<template>
  <div class="form-group">
    <p>{{ label }}<sup v-if="required" class="required">*</sup></p>
    <b-datepicker
      :placeholder="placeholder"
      class="form-control"
      v-model="selectedDate"
      icon="calendar-today"
      :max-datetime="max"
      :min-datetime="min"
      :disabled="disabled"
      trap-focus>
    </b-datepicker>
    <div
      v-if="!$v.selectedDate.required && required && submitted"
      class="required-error">
      {{ label }} is required.
    </div>
    <div v-if="isDateInvalid && required && submitted" class="required-error">
      {{ label }} should be
      {{ label == 'Subscription Start' ? 'less' : 'greater' }} than
      {{
        label == 'Subscription Start'
          ? 'Subscription End'
          : 'Subscription Start'
      }}.
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

/** This component contains the customizable Buefy datepicker component. It is used in the admin panel forms  */
export default {
  name: 'Calendar',
  props: {
    /** This prop contains the placeholder text for the calendar input */
    placeholder: String,
    /** This prop contains the text for the label to be shown above the calendar input */
    label: String,
    /** This prop contains the Date that is selected */
    value: Date,
    /** This prop indicates whether or not a form submission was attempted. */
    submitted: Boolean,
    /** This prop indicates whether or not this input can be left empty or not */
    required: Boolean,
    /** This prop indicates the minimum acceptable Date value */
    min: Date,
    /** This prop indicates the maximum acceptable Date value */
    max: Date,
    /** This prop indicates whether the input is disabled or not */
    disabled: Boolean
  },
  data () {
    return {
      selectedDate: null,
      isDateInvalid: false
    }
  },
  validations () {
    return {
      selectedDate: { required: this.required ? required : false }
    }
  },
  watch: {
    selectedDate () {
      this.validateDate()
    },
    value () {
      this.selectedDate = this.value
    },
    min () {
      this.validateDate()
    },
    max () {
      this.validateDate()
    }
  },
  mounted () {
    this.selectedDate = this.value
    this.$v.$touch()
    this.$emit(
      'onChange',
      this.selectedDate,
      this.$v.$invalid || this.isDateInvalid
    )
  },
  methods: {
    /** This method makes sure that the Date value selected/entered is within the acceptable bounds
     * @public
     */
    validateDate () {
      if (this.min === undefined && this.selectedDate != null) {
        if (
          moment(new Date(this.selectedDate), 'DD/MM/YYYY') <=
          moment(new Date(this.max), 'DD/MM/YYYY')
        ) {
          this.isDateInvalid = false
        } else if (this.max !== null) {
          this.isDateInvalid = true
        }
      }

      if (this.max === undefined && this.selectedDate != null) {
        if (
          moment(new Date(this.selectedDate), 'DD/MM/YYYY') >=
          moment(new Date(this.min), 'DD/MM/YYYY')
        ) {
          this.isDateInvalid = false
        } else if (this.min !== null) {
          this.isDateInvalid = true
        }
      }
      this.$v.$touch()
      this.$emit(
        'onChange',
        this.selectedDate,
        this.$v.$invalid || this.isDateInvalid
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  .required {
    color: red;
  }
  p {
    display: flex;
    margin: 0px;
    color: #7f8fa4;
    font-size: 12px;
    font-family: Quicksand;
    padding: 0px 20px 0px 0px;
    display: flex;
    line-height: 20px;
  }
  .required-error {
    color: red;
    position: absolute;
    font-size: 12px;
  }
}

::v-deep .dropdown-content {
  background: var(--dropdown-backgroud);
  .datepicker-cell.is-selectable {
    color: var(--secondary-text-color) !important;
    &:hover {
      background: var(--dropdown-background-hover) !important;
    }
  }
}
</style>
